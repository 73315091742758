<template>
  <v-container class="success__container">
    <div class="subtitle-1 step__title">{{ $t('success.title') }}</div>
    <h2 class="step__headline">{{ $t('success.headline') }}</h2>
    <h3 class="success__subline" v-html="$t('success.subline')"></h3>
    <v-row>
      <v-col cols="12" sm="6">
        <v-img src="/assets/successScreen_top.png" />
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-end justify-end">
        <app-button class="secondary" :label="$t('success.buttonNew')" @click="reset" />
      </v-col>
    </v-row>
    <hr class="area__divider" />
    <h3 class="success__subline">{{ $t('success.secondSubline') }}</h3>
    <div class="success__image-wrapper">
      <v-img :src="$t('success.detailAssetPath')" />
      <app-button
        :href="$t('success.detailLink')"
        :label="$t('success.buttonDetail')"
        class="secondary detail-button"
        target="_blank"
      />
    </div>
  </v-container>
</template>

<script>
import RouteName from '@/statics/routeName';
import { mapMutations } from 'vuex';

export default {
  name: 'SuccessScreen',

  beforeDestroy() {
    this.resetState();
  },

  methods: {
    ...mapMutations(['resetState']),

    reset() {
      this.$router.push({
        name: RouteName.CONFIGURATOR,
        query: { ...this.$route.query, step: '1' }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.step__title {
  margin: 2rem 0 0.5rem 0;
}
.success__subline {
  margin: 1.5rem 0 2rem;
}

.area__divider {
  margin: 2.5rem 0 2rem 0;
}

.second__subline {
  margin-bottom: 0.5rem;
}

.success__image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 8rem;
}

.detail-button {
  position: absolute;
  bottom: 2rem;
}
.success__container {
  padding: 0 1.5rem;
}
</style>
